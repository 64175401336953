@import 'styles/utils';

.title.title {
  display: block;
  line-height: 2rem;
  margin-bottom: 15px;
  @include breakpoint('lg') {
    line-height: 56px;
    margin-bottom: 20px;
  }
}

.image {
  display: block;
  height: auto;
  width: 100%;
  object-fit: contain;
  padding-bottom: 15px;
  @include breakpoint('md') {
    padding: 0;
  }
}

.richText {
  & p {
    line-height: 24px;
  }
}
